export const Endpoint_Paths = {
    // User API
    LOGIN: '/Login_api_int_',
    SIGNUP: '/SignUp_api_int',
    EDIT_USER: '/editUser_api_int',
    GET_USER_DATA: '/getUserDetails',
    FORCE_SIGNOUT: '/forceSignOut_api_int',
    SIGNOUT: '/signOut_api_int',

    // Trucker API
    DELETE_TRUCKER: '/deleteTrucker_api_int',
    GET_ALL_TRUCKER: '/getAllTrucker_api_int',
    GET_SINGLE_TRUCKER: '/getTruckerDetails_api_int',

    // Trucks API
    ADD_TRUCK: '/addVehicleType_api_int',
    GET_ALL_TRUCKs: '/getAllVehicleType_api_int',
    GET_SINGLE_TRUCK: '/getVehicleType_api_int',
    EDIT_TRUCK: '/editVehicleType_api_int',
    DELETE_TRUCK: '/deleteVehicleType_api_int',
    EXCEL_UPLOAD_TRUCK: '/truckExcelUpload',

    // WAREHOUSE
    ADD_WAREHOUSE_ID: '/createWarehouseId',
    ADD_NEW_WAREHOUSE: '/addWarehouseDetails_api_int',
    GET_ALL_WAREHOUSE: '/getAllWarehouses_api_int',
    GET_SINGLE_WAREHOUSE: '/getWarehouseDetails_api_int',
    EDIT_WAREHOUSE: '/editWarehouseDetails_api_int',
    DELETE_WAREHOUSE: '/deleteWarehouse',
    GET_ALL_WAREHOUSES_BY_COMPANY: '/getAllCompanyWarehouses_api_int',

    // Warehouse User
    ADD_WAREHOUSE_USER: '/addWarehouseUserDetails',
    ADD_WAREHOUSE_MANAGER: '/addManagerDetails_api_int',
    ADD_WAREHOUSE_SUPERVISOR: '/addSupervisorDetails_api_int',
    ADD_WAREHOUSE_LOADING_COORDINATOR: '/addLoadingCoordinatorDetails_api_int',
    GET_WAREHOUSE_USERS: '/getWarehouseUsers_api_int',
    GET_SINGLE_WAREHOUSE_USER: '/getUserWarehouseId',
    EDIT_WAREHOUSE_USER: '/editWarehouseUser',
    EDIT_WAREHOUSEUSER_MANAGER: '/editWarehouseManager_api_int',
    EDIT_WAREHOUSEUSER_SUPERVISOR: '/editWarehouseSupervisor_api_int',
    EDIT_WAREHOUSEUSER_LOADING_COORDINATOR: '/editLoadingCoordinator_api_int',
    DELETE_WAREHOUSE_USER: '/deleteWarehouseUser',
    
    // SUPERVISOR API
    TODAYS_TASK: '/todayTasks_api_int',
    FUTURE_LOOP: '/futureTasks_api_int',
    MARK_VEHICLE_LEFT: '/markVehicleLeft',
    GET_WAREHOUSEID_BY_USERID: '/getWarehouseFromuserId_api_int',
    GET_VEHICLE_DETAILS: '/getVehicleDetails_api_int',
    ALGORITHIMIC_LOADS_WITH_BARCODE: '/algorithmicLoadwithBarcode_api_int',
    GET_LOADING_DETAILS: '/algorithmicLoads_api_int',
    UPDATE_LOADING_DETAILS: '/realTimeLoadsUpdate_api_int',
    GET_REALTIME_LOADS: '/realTimeLoads_api_int',
    UPDATE_UNLOADING_DETAILS: '/updateRealTimeLoadsUnloaded_api_int',

    // RESOURCE ALLOCATOR API
    GET_RESOURCES_ALLOCATOR_LOOPS: '/resourceAllocator/getLoops',
    GET_PIT_STOPS: '/resourceAllocator/getAllPitstops',
    GET_AVG_TARGETS: 'getAverageTargetsFromVehicleTypes',

    // REGION APIS
    ADD_REGION: '/addRegion_api_int',
    GET_ALL_REGIONS: '/getRegions_api_int',
    GET_SINGLE_REGION: '/editRegion_api_int',
    EDIT_REGION: '/editRegion_api_int',
    DELETE_REGION: '/editRegion_api_int',
    UPLOAD_REGION_EXCEL: '/regionExcelUpload',

    // LOADS APIS
    ADD_LOAD: '/addLoadDetails_api_int',
    GET_ALL_LOADS: '/getLoads_api_int',
    GET_SINGLE_LOAD: '/getLoad_api_int',
    EDIT_LOAD: '/editLoad_api_int',
    DELETE_LOAD: '/deletesingleLoad_api_int',
    UPLOAD_LOAD_EXCEL: '/loadExcelUpload',

    // LOAD RATES APIs
    ADD_LOADRATE: '/addLoadRate_api_int',
    GET_LOADRATE: '/getLoadRates_api_int',
    EDIT_LOADRATE: '/editLoadRate_api_int',

    // LOADING RULES
    ADD_LOADING_RULES: '/addloadFamilyDivide_api_int',
    GET_LOADING_RULES: '/getloadFamilyDivide_api_int',

    // LOADING FAMILIES APIs
    GET_LOAD_FAMILIES: '/getLoadFamily_api_int',
    GET_ALL_LOAD_FAMILIES: '/getAllLoadFamily_api_int',
    DELETE_LOAD_FAMILIES: '/deleteLoadFamily_api_int',
    ADD_LOAD_FAMILIES: '/addLoadFamily_api_int',
    EDIT_LOAD_FAMILY: '/editLoadFamily_api_int',

    // NSA APIs
    GET_NSA_DATA: '/nsaResult_api_int',
    GET_UNIQUE_DATES: '/getUniqueDates',
    GET_SOURCE_CITIES: '/getSourceCities',
    GET_FILTERED_STREAMS: '/getFilteredStreams',
    GET_PARTICULAR_STREAM: '/getParticularStream',

    // FLEET MANAGEMENT APIs
    ADD_FLEET_MANAGER: '/addFleetManager_api_int',
    ADD_TRUCKER: '/addTrucker_api_int',

    // Driver
    GET_ALL_DRIVERS: '/getAllDriver_api_int',
    ADD_DRIVER: '/addDriver_api_int',
    EDIT_DRIVER: '/editDriver_api_int',
    DELETE_DRIVER: '/deleteDriver_api_int',
    GET_NO_OF_TRUCKS_DRIVERS: '/getNoOfDriversNoOfTrucksPerTrucker',
    
    // Vehicle
    GET_ALL_VEHICLES: '/getAllVehicles_api_int', 
    GET_VEHICLE_DATA: '/getVehicleDetails_api_int',
    ADD_VEHICLE: '/addVehicle_api_int',
    EDIT_VEHICLE: '/editVehicle_api_int',
    DELETE_VEHICLE: '/deleteVehicle_api_int',

    // NOTIFICATION APIs
    GET_NOTIFICATIONS: '/notification',
    MARK_NOTIFICATION_READ: '/markNotificationAsRead',

    ADD_DELIGATION: '/delegateWork',
    GET_SUCCESSFUL_LOOP: '/getSuccessfulLoops',

    // SUPERVISOR API
}