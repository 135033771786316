import React from 'react';
import styles from './CustomLoader.module.css';

const Box3dLoader = () => {

  return (
    <div className={styles.boxLoaderContainer}>
        <div className={styles.boxLoader}>
        {
          Array.from({ length: 8 }).map((_, n) => (
            <div key={n} className={`${styles.box} ${styles['box' + n]}`}>
              <div></div>
            </div>
          ))
        }
        <div className={styles.ground}>
            <div></div>
        </div>
        </div>
    </div>
  );
};

export default Box3dLoader;
