import axios from "axios";
import { getToken, getUserId } from "../apiService";
import { Envs } from "../../components/GlobalComp/Env";
import { Endpoint_Paths } from "../EndpointPaths";

const backendURL = Envs?.[process.env.NODE_ENV]?.BACKEND_URL

// ==========================================================
// ================= LOAD APIs =============================
// ==========================================================


// ADD NEW  LOAD
export async function addNewLoad(companyId, loadDetails) {
    const token = await getToken();
    const requesterId = getUserId()
    try{
    const resp = await axios.post(`${backendURL}${Endpoint_Paths.ADD_LOAD}`, 
        loadDetails,    
        {
            params:{
               companyId: companyId,
            //    loadTypeId: loadId,
               requesterId
            },
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        return resp
        }
    catch(err){
        return err?.response
    }
  }

  // Get ALL LOAD BY COMPANY-ID
  export async function getAllLoads(companyId) {
    try{
    const token = await getToken();
    const resp = await axios.get(`${backendURL}${Endpoint_Paths.GET_ALL_LOADS}`, {
        params:{
            "companyId": companyId
        },    
        headers:{
            'apikey': 'k3n5POSr7Rn2jMWksmY',
            'token': token
        }
    });
    return resp
    } catch(err){
        return err?.response
    }
  }

  // Get ONE LOAD by COMPANY-ID & LOAD-ID
  export async function getOneLoad(companyId, loadId) {
    const token = await getToken();
    return await axios.get(`${backendURL}${Endpoint_Paths.GET_SINGLE_LOAD}`, {
        params:{
            "companyId": companyId,
            "loadId": loadId
        },    
        headers:{
            'apikey': 'k3n5POSr7Rn2jMWksmY',
            'token': token
        }
    });
  }

  // UPDATE LOAD Details
  export async function updateLoad(companyId, loadId, updatedLoadDetails) {
    const token = await getToken();
    const requesterId = getUserId()
    try{
        const resp = await axios.put(`${backendURL}${Endpoint_Paths.EDIT_LOAD}`, updatedLoadDetails,
        {
            params:{
                companyId,
                loadId: loadId,
                requesterId
            },    
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
    if(resp.status === 200)
            return { data: resp.data, status:'success'}
        else
            throw new Error(resp);
        }
    catch(err){
        if(err.response.status === 400){
            return { msg: err.response.data.msg, status:'error' }
        } 
        return { msg: err?.response?.data?.msg ?? err?.message, status: 'error'}
    }
  }

  // DELETE LOAD 
  export async function deleteLoad(companyId, loadIds) {
    const token = await getToken();
    const requesterId = getUserId()
    const payload = {
            loadIds: [...loadIds]
        }
    return await axios.post(`${backendURL}${Endpoint_Paths.DELETE_LOAD}`, payload, {
        params:{
            "companyId": companyId,
            requesterId
        },    
        headers:{
            'apikey': 'k3n5POSr7Rn2jMWksmY',
            'token': token
        },
        data: payload
    });
  }

  // Upload Load Excel Data
  export async function uploadLoadExcel(companyId, excelFile) {
    const token = await getToken();
    const requesterId = getUserId()
    try{
        const resp = await axios.post(`${backendURL}${Endpoint_Paths.UPLOAD_LOAD_EXCEL}`, excelFile, {
            params:{
                "companyId": companyId,
                requesterId
            },    
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        if(resp.status===200)
            return { status: 'success', data: {...resp.data} }
        else
            throw new Error(resp);
    }
    catch(err){
        if(err.response.status === 400){
            return {status:'error', data: err.response.data}
        }
        else{
            return {status:'error', data: 'Internal Server Error'}
        }
    }
  }