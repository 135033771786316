import axios from "axios";
import {getToken, getUserId} from '../apiService';
import { Envs } from "../../components/GlobalComp/Env";
import { Endpoint_Paths } from "../EndpointPaths";

const backendURL = Envs?.[process.env.NODE_ENV]?.BACKEND_URL

export async function TodaysTask(companyId, warehouseId) {
    const token = await getToken();
    try{
        
        const resp = await axios.get(`${backendURL}${Endpoint_Paths.TODAYS_TASK}`, 
            {
                params:{
                    'companyId':companyId,
                    'entityId':warehouseId
                },
                headers:{
                    'apikey': 'k3n5POSr7Rn2jMWksmY',
                    'token': token
                }
            });
        if(resp.status === 200)
            return { data: resp.data, status:'success'}
        else
            throw new Error(resp);
    }
    catch(err){
        console.log("Errror==>", err);
        if(err.response.status === 400){
            return { msg: err.response.data.msg, status:'error' }
        }
    }
  }


  export async function FutureTask(companyId, warehouseId) {
    const token = await getToken();
    try{
        
        const resp = await axios.get(`${backendURL}${Endpoint_Paths.FUTURE_LOOP}`, 
            {
                params:{
                    'companyId':companyId,
                    'entityId':warehouseId
                },
                headers:{
                    'apikey': 'k3n5POSr7Rn2jMWksmY',
                    'token': token
                }
            });
        if(resp.status === 200)
            return { data: resp.data, status:'success'}
        else
            throw new Error(resp);
    }
    catch(err){
        console.log("Errror==>", err);
        if(err.response.status === 400){
            return { msg: err?.response?.data?.msg, status:'error' }
        }
    }
  }

  export async function getWarehouseDetails(companyId, regionId) {   // RegionId= AreaId
    try{
        const token = await getToken();
        
        const resp = await axios.get(`${backendURL}${Endpoint_Paths.GET_ALL_WAREHOUSE}`, 
            {
                params:{
                    'companyId':companyId,
                    'regionId':regionId
                },
                headers:{
                    'apikey': 'k3n5POSr7Rn2jMWksmY',
                    'token': token
                }
            });
        if(resp.status === 200)
            return { data: resp.data, status:'success'}
        else
            throw new Error(resp);
    }
    catch(err){
        console.log("Errror==>", err);
        if(err.response.status === 400){
            return { msg: err.response?.data?.msg, status:'error' }
        }
        return { msg: 'Some Error Occurred', status:'error' }
    }
  }


  export async function markVehicleLeft(companyId, warehouseId, vehicleNo, body) {   // RegionId= AreaId
    try{
        const token = await getToken();
        const requesterId = getUserId()
        const resp = await axios.post(`${backendURL}${Endpoint_Paths.MARK_VEHICLE_LEFT}`, body, 
            {
                params:{
                    companyId,
                    vehicleNo,
                    warehouseId,
                    requesterId
                },
                headers:{
                    'apikey': 'k3n5POSr7Rn2jMWksmY',
                    'token': token
                }
            });
        if(resp.status === 200)
            return { data: resp.data, status:'success'}
        else
            throw new Error(resp);
    }
    catch(err){

        return { msg: err.response?.data?.msg ?? err?.message ?? 'Some Error Occurred', status:'error' }
    }
  }

  export async function getWarehouseIdByUserId(compId, userId) {   // RegionId= AreaId
    try{
        const token = await getToken();
        
        const resp = await axios.get(`${backendURL}${Endpoint_Paths.GET_WAREHOUSEID_BY_USERID}`, 
            {
                params:{
                    companyId: compId,
                    'userId': userId
                },
                headers:{
                    'apikey': 'k3n5POSr7Rn2jMWksmY',
                    'token': token
                }
            });
            return { data: resp?.data, status:'success'}
    }
    catch(err){
        if(err?.response?.status === 400){
            return { msg: err?.response?.data?.msg, status:'error' }
        }
        return { msg: 'Some Error Occurred', status:'error' }
    }
  }