import React, { Fragment, lazy, Suspense } from 'react'
import { useContext } from 'react';
import { DesignationID } from './components/GlobalComp/constant/DesignationId';
import { ThemeProvider, createTheme } from "@mui/material";
import { MainContext } from './components/ContextAPI/MainContext';
import MainLoadingComponent from './components/GlobalComp/MainLoadingComp';
import { useSelector } from 'react-redux';
const SupervisorDashboard = lazy(() => import('./MODULES/Supervisor/SupervisorDashboard'));
const HomeDashboard = lazy(() => import('./MODULES/Dashboard/HomeDashboard'));

export default function MainComp() {
    const theme = createTheme({
        palette: {
          primary: { main: '#0353b4' },
          success: { main: '#3eaa00' },
          green: { main: '#00C287' }
        },
      });
  // Context State
  const contextState = useContext(MainContext);
  const { clearStates } = contextState

  const reduxIsLoaded = useSelector(state => state?.isLoaded)
  const accessId = useSelector(state => state?.userData?.designationId)
  return (
    <>
      {
        reduxIsLoaded
        ? <Fragment>
            {(accessId === DesignationID.SUPERVISOR || accessId === DesignationID.MANAGER) ? (
                <Suspense fallback={<div>Loading Supervisor Dashboard...</div>}>
                  <SupervisorDashboard clearStates={clearStates} />
                </Suspense>
                ) : (
                  <ThemeProvider theme={theme}>
                    <Suspense fallback={<MainLoadingComponent showLogo={true} loadingText={'Loading Dashboard...'}/>}>
                      <HomeDashboard/>
                    </Suspense>
                  </ThemeProvider>
                )}
          </Fragment>
        : <MainLoadingComponent showLogo={true}/>
      }
    </>
  )
}
