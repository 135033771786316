import axios from "axios";
import { getToken, getUserId } from "../apiService";
import { Envs } from "../../components/GlobalComp/Env";
import { Endpoint_Paths } from "../EndpointPaths";

const backendURL = Envs?.[process.env.NODE_ENV]?.BACKEND_URL


// =================== FLEET MANAGER API ====================
export async function addFleetManager(companyId, managerDetails) {
    const requesterId = getUserId()
    const token = await getToken();
    // console.log(token);
    try{
    const resp = await axios.post(`${backendURL}${Endpoint_Paths.ADD_FLEET_MANAGER}`, 
    managerDetails,    
        {
            params:{
               companyId: companyId,
               requesterId
            },
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        if(resp.status === 200)
            return { data: resp.data, status:'success'}
        else
            throw new Error(resp);
        }
    catch(err){
        if(err.response.status === 400){
            return { msg: err.response.data.msg, status:'error' }
        }
        else{
            return {msg: err.message, status:'error'}
        }
    }
  }


// =========== TRUCKER API ============= 
export async function addTruckerDetail(companyId, truckerDetails) {
    const requesterId = getUserId()
    const token = await getToken();
    // console.log(token);
    try{
    const resp = await axios.post(`${backendURL}${Endpoint_Paths.ADD_TRUCKER}`, 
    truckerDetails,    
        {
            params:{
               companyId: companyId,
               requesterId
            },
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        if(resp.status === 200)
            return { data: resp.data, status:'success'}
        else
            throw new Error(resp);
        }
    catch(err){
        if(err.response.status === 400){
            return { msg: err.response.data.msg, status:'error' }
        }
        else{
            return {msg: err.message, status:'error'}
        }
    }
  }

  export async function editTruckerDetail(userId, companyId, truckerDetails) {
    const requesterId = getUserId()
    const token = await getToken();
    // console.log(token);
    try{
    const resp = await axios.put(`${backendURL}${Endpoint_Paths.EDIT_USER}`, 
    truckerDetails,    
        {
            params:{
               companyId,
               truckerId: userId,
               requesterId
            },
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        if(resp.status === 200)
            return { data: resp.data, status:'success'}
        else
            throw new Error(resp);
        }
    catch(err){
        if(err.response.status === 400){
            return { msg: err.response.data.msg, status:'error' }
        }
        else{
            return {msg: err.message, status:'error'}
        }
    }
  }

    // Get ALL TRUCKERS
    export async function getAllTrucker(companyId) {
        const token = await getToken();
        try{
            const resp = await axios.get(`${backendURL}${Endpoint_Paths.GET_ALL_TRUCKER}`, {
                params:{
                    "companyId": companyId
                },    
                headers:{
                    'apikey': 'k3n5POSr7Rn2jMWksmY',
                    'token': token
                }
            });
            return resp;
        } catch (err) {
            return err?.response
        }
      }

    // DELETE TRUCKER
    export async function deleteTrucker(companyId, truckerId, emailId) {
        const requesterId = getUserId()
        const token = await getToken();
        // console.log(token);
        try{
        const resp = await axios.post(`${backendURL}${Endpoint_Paths.DELETE_TRUCKER}`,  { email: emailId },  
            {
                params:{
                   companyId: companyId,
                   truckerId,
                   requesterId
                },
                headers:{
                    'apikey': 'k3n5POSr7Rn2jMWksmY',
                    'token': token
                }
            });
            if(resp.status === 200)
                return { data: resp?.data, status:'success'}
            else
                throw new Error(resp);
            }
        catch(err){
            if(err.response.status === 400){
                return { msg: err.response?.data?.msg, status:'error' }
            }
            else{
                return {msg: err?.message, status:'error'}
            }
        }
      }

  // ========== Driver Detail ==============
  export async function addDriverDetail(companyId, truckerId, driverDetails) {
    const requesterId = getUserId()
    const token = await getToken();
    // console.log(token);
    try{
        const resp = await axios.post(`${backendURL}${Endpoint_Paths.ADD_DRIVER}`, 
        driverDetails,    
            {
                params:{
                    companyId: companyId,
                    truckerId,
                    requesterId
                },
                headers:{
                    'apikey': 'k3n5POSr7Rn2jMWksmY',
                    'token': token
                }
            });
            if(resp.status === 200)
                return { data: resp.data, status:'success'}
            else
                throw new Error(resp);
    }
    catch(err){
        console.log(err?.response, err)
            return {msg: err?.response?.data?.msg ?? err.message, status:'error'}
    }
  }


  export async function editDriverDetail(userId, companyId, driverDetails) {
    const requesterId = getUserId()
    const token = await getToken();
    // console.log(token);
    try{
        const resp = await axios.put(`${backendURL}${Endpoint_Paths.EDIT_DRIVER}`, 
        driverDetails,    
            {
                params:{
                    driverId: userId,
                    companyId,
                    requesterId
                },
                headers:{
                    'apikey': 'k3n5POSr7Rn2jMWksmY',
                    'token': token
                }
            });
            if(resp.status === 200)
                return { data: resp.data, status:'success'}
            else
                throw new Error(resp);
    }
    catch(err){
        if(err.response.status === 400){
            return { msg: err.response.data.msg, status:'error' }
        }
        else{
            return {msg: err.message, status:'error'}
        }
    }
  }

  // Delete driver
  export async function deleteDriverDetail(companyId, driverId) {
    const requesterId = getUserId()
    const token = await getToken();
    // console.log(token);
    try{
    const resp = await axios.delete(`${backendURL}${Endpoint_Paths.DELETE_DRIVER}`,    
        {
            params:{
               companyId: companyId,
               driverId,
               requesterId
            },
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        if(resp.status === 200)
            return { data: resp.data, status:'success'}
        else
            throw new Error(resp);
        }
    catch(err){
        if(err.response.status === 400){
            return { msg: err.response.data.msg, status:'error' }
        }
        else{
            return {msg: err.message, status:'error'}
        }
    }
  }


    // Get ALL DRIVERS BY TRUCKER-ID
    export async function getAllDriversByTruckerId(companyId, truckerId) {
        // const requesterId = getUserId()
        try {
            const token = await getToken();
            // console.log(token);
            return await axios.get(`${backendURL}${Endpoint_Paths.GET_ALL_DRIVERS}`, {
                params: {
                    "truckerId": truckerId,
                    companyId
                },
                headers: {
                    'apikey': 'k3n5POSr7Rn2jMWksmY',
                    'token': token
                }
            });
        }
        catch (err) {
            return err?.response
        }
    }

  // ========== Vehicle Detail ==============
  export async function addVehicleDetail(companyId, vehicleNo, vehicleDetails) {
    const requesterId = getUserId()
    const token = await getToken();
    // console.log(token);
    try{
    const resp = await axios.post(`${backendURL}${Endpoint_Paths.ADD_VEHICLE}`, 
    { ...vehicleDetails },    
        {
            params:{
               companyId: companyId,
               vehicleNo: vehicleNo,
               requesterId
            },
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        if(resp.status === 200)
            return { data: resp.data, status:'success'}
        else
            throw new Error(resp);
        }
    catch(err){
        if(err?.response?.status === 400){
            return { msg: err?.response?.data?.msg, status:'error' }
        }
        else{
            return {msg: err?.response?.data?.msg ?? err?.message, status:'error'}
        }
    }
  }

// EDIT VEHICLE DETAILS
export async function editVehicleDetail(companyId, vehicleNo, vehicleDetails) {
    const requesterId = getUserId()
    const token = await getToken();
    // console.log(token);
    try{
    const resp = await axios.put(`${backendURL}${Endpoint_Paths.EDIT_VEHICLE}`, 
    vehicleDetails,    
        {
            params:{
               companyId: companyId,
               vehicleNo,
               requesterId
            },
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        if(resp.status === 200)
            return { data: resp.data, status:'success'}
        else
            throw new Error(resp);
        }
    catch(err){
        if(err.response.status === 400){
            return { msg: err.response.data.msg, status:'error' }
        }
        else{
            return {msg: err.message, status:'error'}
        }
    }
  }

// DELETE Vehicle
export async function deleteVehicleDetail(companyId, vehicleNo) {
    const requesterId = getUserId()
    const token = await getToken();
    // console.log(token);
    try{
    const resp = await axios.delete(`${backendURL}${Endpoint_Paths.DELETE_VEHICLE}`,    
        {
            params:{
               companyId: companyId,
               vehicleNo,
               requesterId
            },
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        if(resp.status === 200)
            return { data: resp.data, status:'success'}
        else
            throw new Error(resp);
        }
    catch(err){
        if(err.response.status === 400){
            return { msg: err.response.data.msg, status:'error' }
        }
        else{
            return {msg: err.message, status:'error'}
        }
    }
  }

// Get ALL VEHICLES BY TRUCKER-ID
export async function getAllVehicles(companyId, truckerId) {
    const token = await getToken();
    // console.log(token);
    return await axios.get(`${backendURL}${Endpoint_Paths.GET_ALL_VEHICLES}`, {
        params:{
            "companyId": companyId,
            truckerId
        },    
        headers:{
            'apikey': 'k3n5POSr7Rn2jMWksmY',
            'token': token
        }
    });
}

// Get ALL VEHICLES BY TRUCKER-ID
export async function getVehicleDetails(companyId, vehicleNo, userId) {
    try{
        const token = await getToken();
        // console.log(token);
        const resp = await axios.get(`${backendURL}${Endpoint_Paths.GET_VEHICLE_DATA}`, {
            params:{
                "companyId": companyId,
                vehicleNo,
                truckerId: userId
            },    
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        return resp;
    } catch (err){
        return err?.response
    }
}


// Get User Details By EmpID
export async function getUserDetailsByEmpId(empId, companyId) {
    const token = await getToken();
    let loginResp;
    try{
        loginResp = await axios.get(`${backendURL}${Endpoint_Paths.GET_USER_DATA}`, {
            params:{
                userId: empId,
                companyId: companyId
            },    
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        if(loginResp.status===200)
        {
            return {
                status: "success",
                data: loginResp.data
            }
        }

        return {
            status: "error",
            message: loginResp?.data?.msg,
            data: loginResp.data
        }

    }
    catch(err){
        return {
            status: "error",
            message: err?.response?.data?.msg ?? err?.message,
            data: err?.response?.data
        }
    }
  }


  export async function getNoOfTrucksAndDrivers(truckerId, companyId) {
    try {
        const token = await getToken();
        // console.log(token);
        const resp = await axios.get(`${backendURL}${Endpoint_Paths.GET_NO_OF_TRUCKS_DRIVERS}`, {
            params: {
                truckerId,
                companyId
            },
            headers: {
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });

        return resp
    }
    catch (err) {
        return err?.response
    }
}