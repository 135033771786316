import { FutureTask, getWarehouseDetails, TodaysTask } from './ModuleAPIs/SupervisorAPI';
import { updateUsers, getUserDetails, checkIfExists, registerUser } from './ModuleAPIs/UserAPI';
import {addNewRegion, getAllRegions, getOneRegion, updateRegion, deleteRegion, uploadRegionExcel} from './ModuleAPIs/RegionsAPI';
import { addNewTruck, getAllTrucks, getOneTruck, updateTruck, deleteTruck, uploadTrucksExcel} from './ModuleAPIs/TrucksAPI';
import {addNewLoad, getAllLoads, getOneLoad, updateLoad, deleteLoad, uploadLoadExcel } from './ModuleAPIs/LoadsAPI'
import {addLoadUnloadRate, getLoadRate, updateLoadRate, addNewLoadRates } from './ModuleAPIs/LoadRatesAPI'
import { getLoadFamily, deleteLoadFromFamily, addLoadFamily, getAllLoadFamily } from './ModuleAPIs/LoadFamiliesAPI'
import { uploadArchitectureExcel } from './ModuleAPIs/RegionsAPI';
import { getWarehouseId, addNewWarehouse, addNewUserToWarehouse, getWarehousesByRegion, getOneWarehouse, updateWarehouse, deleteWarehouse, getWarehouseUsers, updateWarehouseUsers, deleteWarehouseUser } from './ModuleAPIs/WarehouseAPI';
import { addNewDelignation } from './ModuleAPIs/DeligationAPI';
import { GetAddressByCoordinates_MapMyIndia, GetAddressByCoordinates_Google } from './ModuleAPIs/MapsAPI';
import { AddLoadingRule, GetLoadingRules } from './ModuleAPIs/LoadingRulesAPI';
import { detectionInternetConnection, GetAllCities, IsPhoneNumberExists, IsEmailExists } from './ModuleAPIs/AdditionalAPI';
import { deleteVehicleDetail, editTruckerDetail, editDriverDetail, getUserDetailsByEmpId, editVehicleDetail, addFleetManager,addTruckerDetail, getAllTrucker, addDriverDetail, getAllDriversByTruckerId, addVehicleDetail, getAllVehicles, getVehicleDetails} from './ModuleAPIs/FleetManagerAPI';
import { getAllNotifications, markNotificationAsRead } from './ModuleAPIs/NotificationsAPI';
import { getUniqueDates } from './ModuleAPIs/NSAApi';
import { Envs } from '../components/GlobalComp/Env';

const axios = require('axios');

// URL for backend
const backendURL = Envs?.[process.env.NODE_ENV]?.BACKEND_URL

export async function getToken() {
  try{
    const tkn = await axios.get(`${backendURL}/createToken`, {
            headers: {
              'apikey': 'k3n5POSr7Rn2jMWksmY',
              'secretkey': 'jUeBXaCvKQuDXDaMYVw'
            }
    });
    return tkn.data["token"];
  }
  catch(err){
    if(err.message === 'Network Error'){
      window.location.href='/dashboard';
    }
    return 'error';
  }
  }

export function getUserId () {
  return localStorage.getItem('userId') ?? ''
}

// ====== Users API =======
export {getUserDetails, checkIfExists, registerUser, updateUsers }

// ====== Regions API =====
export {addNewRegion, getAllRegions, getOneRegion, updateRegion, deleteRegion, uploadRegionExcel}

// ========== TRUCK APIs ==============
export { addNewTruck, getAllTrucks, getOneTruck, updateTruck, deleteTruck, uploadTrucksExcel}

// ========== LOAD APIs ===============
export {addNewLoad, getAllLoads, getOneLoad, updateLoad, deleteLoad, uploadLoadExcel }

// ======== LOAD RATES ==============
export {addLoadUnloadRate, getLoadRate, updateLoadRate, addNewLoadRates }

// ========= LOAD FAMILIES API ==========
export { getLoadFamily, deleteLoadFromFamily, addLoadFamily, getAllLoadFamily }

// ======= ARCHITECTURE ============
export { uploadArchitectureExcel }

// ======= WAREHOUSE API =======
export { getWarehouseId, addNewWarehouse, addNewUserToWarehouse, getWarehousesByRegion, getOneWarehouse, updateWarehouse, deleteWarehouse, getWarehouseUsers, updateWarehouseUsers, deleteWarehouseUser }

// ======== Deligations APIs =========
export { addNewDelignation }


// ====== MapMyIndia Geocodings APIs ==========
export { GetAddressByCoordinates_MapMyIndia, GetAddressByCoordinates_Google }

// ======== LOADING RULES ============
export { AddLoadingRule, GetLoadingRules }

// ========== ADDITIONAL APIS =========
export {GetAllCities, IsPhoneNumberExists, IsEmailExists,  detectionInternetConnection }

// ================ SUPERVISOR MODULE ================
export { TodaysTask, getWarehouseDetails, FutureTask }

// ============ Fleet MANAGER APIS ========= 
export { deleteVehicleDetail, 
          editTruckerDetail, 
          editDriverDetail, 
          getUserDetailsByEmpId, 
          addFleetManager, 
          addTruckerDetail, 
          getAllTrucker, 
          addDriverDetail,
          getAllDriversByTruckerId, 
          editVehicleDetail,
          addVehicleDetail, 
          getAllVehicles, 
          getVehicleDetails 
        }

// =============== Notification APIs ============
export { getAllNotifications, markNotificationAsRead }

// ============== NSA API ============
export { getUniqueDates }






