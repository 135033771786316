import axios from "axios";
import { getToken } from "../apiService";
import { Envs, TWO_FACTOR_CONFIG } from "./../../components/GlobalComp/Env";

const backendURL = Envs?.[process.env.NODE_ENV]?.BACKEND_URL

// ========================================================
// ================ ADDITIONAL APIS =======================
// ========================================================
export async function GetAllCities() {
    try {
        const token = await getToken();
        const response = await axios.get(`${backendURL}/getCities`, {
            headers: {
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        return response
    } catch (err) {
        return err?.response
    }
}

//  Function To check if Number already exists in the records
// If Number already exists => Returns true
// New Number => returns false
 export async function IsPhoneNumberExists(num){
    const token = await getToken();
    try{
        const response = await axios.get(`${backendURL}/checkPhoneNo?phone_number=${num}`, { 
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });

        if(response?.status===200){
            if(response?.data?.msg?.includes("already exists")){
                return true;
            }
            return false;
        }
        else{
            return true;
        }
    }
    catch(err){
        if(err?.response?.status===400){
            return true;
        }

        // If API Fails - It will pass the validation Check
        return false
    }
    // return true;
 }

 export async function IsEmailExists(email){
    const token = await getToken();
    try{
        const response = await axios.get(`${backendURL}/checkEmail?email=${email}`, { 
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        if(response?.status===200){
            if(response?.data?.msg === "email already exists"){
                return true;
            }
            return false;
        }
        else{
            return true;
        }
    }
    catch(err){
        if(err?.response?.status===400){
            if(err?.response?.data?.msg === "email already exists"){
                return true;
            }
        }
        // If API Fails - It will pass the validation Check
        return false
    }
    // return true;
 }

 // ====== Check Internet Connection ===========
 export async function detectionInternetConnection(){
     try{
        const response = await axios.get(`${backendURL}/checkEmail?email=test@gmail.com}`, { 
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': 'token'
            }
        });
        return "online";
     }
     catch(err){
        if(err.message === 'Network Error'){
            return "offline"
         }
         else{
             return "online";
         }
     }
 }


 export async function SendEmailVerificationCode(name, email, code){
    const body = {
        ih_name: name,
        ih_otp: code,
        ih_email:email
    };
    const resp = await axios.post(`https://api.arengu.com/flows/166219379139881810/executions/`, body);
    if(resp.status === 202){
        return {status:'success', body: resp?.data || 'OTP Sent Successfully'};
    }
    else{
        return {status:'error', body: resp?.data || 'Some Error Occured'};
    }
 }


 export async function SendPhoneVerificationCode(name, mobile, code){
    const body = {
        ih_name: name,
        ih_otp: code,
        ih_mobile:mobile
    };
    const resp = await axios.post(`https://api.arengu.com/flows/166222540875573245/executions/`, body);
    if(resp.status === 202){
        return {status:'success', body: resp?.data || 'OTP Sent Successfully'};
    }
    else{
        return {status:'error', body: resp?.data || 'Some Error Occured'};
    }
 }


 export async function SendGenerateOTP(mobile){
    // For testing Purpose
    // const resp = {status:200, data:{
    //     "Status": "Success",
    //     "Details": "517b029d-39a9-11ed-9c12-0200cd936042",
    //     "OTP": "330311"
    // }};
    const resp = await axios.get(`https://2factor.in/API/V1/${TWO_FACTOR_CONFIG.API_KEY}/SMS/${mobile}/AUTOGEN2/Ih_otp`);
    if(resp.status === 200){
        return {status:'success', data: resp?.data || 'OTP Sent Successfully'};
    }
    else{
        return {status:'error', data: resp?.data || 'Some Error Occured'};
    }
 }

// ===== Country code =======

export async function GetListOfCountries(){
    try{
       const response = await axios.get(`https://api.first.org/data/v1/countries`, { 
           params: {
            limit: 200,
           }
       });
       return response;
    }
    catch(err){
       return err?.response
    }
}

export async function GetCountryDetailByCode(countryCode){
    try{
       const response = await axios.get(`https://restcountries.com/v3.1/alpha/${countryCode}`);
       return response;
    }
    catch(err){
       return err?.response
    }
}