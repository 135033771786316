export const DesignationID = {
    COMPANY_HEAD:  1,
    COMPANY_COORDINATOR:  2,
    REGIONAL_HEAD :  3,
    MANAGER:  4,
    SUPERVISOR:  5,
    WAREHOUSE_COORDINATOR:  6,
    FLEET_MANAGER:  7,
    ARCHITECTURE: 8,
    TRUCKER: 8,
    DRIVER: 9,
    LOAD_MANAGER: 10,       // DELEGATION ID  (Used When designationId = 0)
    TRUCK_MANAGER: 20,      // DELEGATION ID (Used When designationId = 0)
    EMPLOYEE_WITH_DELEGATION : 0
}

export const DesignationRoleNames = {
    1: 'Company Head',
    2: 'Company Co-ordinator',
    3: 'Regional Head',
    4: 'Manager',
    5: 'Supervisor',
    6: 'Warehouse Co-ordinator',
    0: 'Employee With Delegation',
    7: 'Fleet Manager'
};