import axios from "axios";
import { getToken, getUserId } from "../apiService";
import { Envs } from "../../components/GlobalComp/Env";
import { Endpoint_Paths } from "../EndpointPaths";

const backendURL = Envs?.[process.env.NODE_ENV]?.BACKEND_URL

// ========================================================
// ================= LOADING RULES ========================
// ========================================================
export async function AddLoadingRule(companyid, truckid, rulesArr){
    const requesterId = getUserId()
    const token = await getToken();
    const respObj = {
        rules: [...rulesArr]
    }
    try{
        const response = await axios.post(`${backendURL}${Endpoint_Paths.ADD_LOADING_RULES}`, respObj, {
            params:{
                companyId: companyid,
                vehicleTypeId: truckid,
                requesterId
            },  
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        return response
    }
    catch(err){
        return err?.response
    }
 }

 export async function GetLoadingRules(companyid, truckid){
    const token = await getToken();
    const response = await axios.get(`${backendURL}${Endpoint_Paths.GET_LOADING_RULES}`, {
        params:{
            companyId: companyid,
            vehicleTypeId: truckid
        }, 
        headers:{
            'apikey': 'k3n5POSr7Rn2jMWksmY',
            'token': token
        }
    });
    return response.data;
 }