import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import IHLogo from './../../static/images/haul.png'
import PropTypes from 'prop-types'
import Box3dLoader from './CustomLoaders/Box3DLoaderComp';

// This Loader is for Whole page Loading (Full Page)
const MainLoadingComponent = ({ showLogo, showLoadingText, loadingText, fullHeight }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: fullHeight ? '100vh' : '100%',
        backgroundColor: '#f4f4f4',
      }}
    >

      {/* === IH Logo ===  */}
      {
        showLogo && <img 
        src={IHLogo} // Replace with your logo path
        alt="Logo"
        style={{ width: 200, marginBottom: 20 }}
      />
      }
      
      {/* ==== Loader ==== */}
      { fullHeight ? <Box3dLoader/> : <CircularProgress size={45} thickness={5} />}

      {/* == Loading Text === */}
      {
        showLoadingText &&
        <p style={{ fontSize: 20, fontWeight: 'bold', color: '#333' }}>
          { loadingText }
        </p>
      }
    </Box>
  );
};

export default MainLoadingComponent;

MainLoadingComponent.propTypes = {
  showLogo: PropTypes.bool, 
  showLoadingText: PropTypes.bool, 
  loadingText: PropTypes.string,
  fullHeight: PropTypes.bool
}

MainLoadingComponent.defaultProps = {
  showLogo: false, 
  showLoadingText: true, 
  loadingText: 'Loading...',
  fullHeight: true
}