import axios from "axios";
import { creds } from "../../components/GlobalComp/globalstates";
var qs = require('qs');


// Function To Format  Adress Response
async function FormatAddress(resp, source){
  if(source==="MapMyIndia"){
    let format_obj={
      formatted_address: resp.formatted_address,
      city: resp.city,
      state: resp.state,
      pincode: resp.pincode,
      homeaddress: ''
    };
    return format_obj;
  }
  else if(source==="Google"){
    const address_components = [...(resp?.address_components || [])];
    //console.log(address_components.length);
    let format_obj={
      formatted_address: resp?.formatted_address,
      short_name: resp?.address_components?.[0]?.short_name,
    };
    let buildingstreetname='';
    for (let i = 0; i < address_components.length; i++) {
      //console.log("a==>",address_components[i])
      const element = address_components[i];
      if(element.types.includes("street_number") || element.types.includes("plus_code")){
        buildingstreetname = buildingstreetname + element.long_name + " ";
      }
      if(element.types.includes("route")){
        buildingstreetname = buildingstreetname + element.long_name + " ";
      }
      if(element.types.includes("premise") || element.types.includes("sublocality")){
        buildingstreetname = buildingstreetname + element.long_name + " ";
      }
      else if(element.types.includes("administrative_area_level_2") || element.types.includes("locality")){
        format_obj = {...format_obj, city: element.long_name}
      }
      else if(element.types.includes("administrative_area_level_1")){
        format_obj = {...format_obj, state: element.long_name}
      }
      else if(element.types.includes("postal_code")){
        format_obj = {...format_obj, pincode: element.long_name}
      }
      
    }//end-for

    format_obj = {...format_obj, homeaddress: buildingstreetname}
    //console.log("Formatted Address: " , format_obj);
    return format_obj;
  }
}

// =================================================================
// ================= MapMyIndia Geocodings APIs ====================
// =================================================================
async function GetAccessToken(){
    const data={
        grant_type: "client_credentials",
        client_id: "33OkryzDZsIE0x2WnA9OwzwQBFDTcV_mo_kYPgUUCaFWBwuGC9FDpGLBqBea4p1LHlHISHxVsBTnJfvtfHcPXg==",
        client_secret: "lrFxI-iSEg8qh6iAuCUa7vUTz0AHOGqO2zPcTrlhR9p9LHOXoYTUPid7QtnTn1pGysnpQzyJPLEL1fC4lnoWIueg3INvm6T0"
      }
    const resp = await axios.post('https://outpost.mapmyindia.com/api/security/oauth/token', qs.stringify(data), {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    return resp.data;
}

export async function GetAddressByCoordinates_MapMyIndia(latitude, longitude){
    const data = {
        lat: latitude,
        lng:longitude,
        region: 'IND',
        lang: 'en'
    }
    const accessToken = await GetAccessToken();
    const resp = await axios.get(`https://apis.mapmyindia.com/advancedmaps/v1/${accessToken.access_token}/rev_geocode`, {params: {...data}});
    return await FormatAddress(resp.data, "MapMyIndia");
}

// =================================================================
// ================= GOOGLE Geocodings APIs ====================
// =================================================================

export async function GetAddressByCoordinates_Google(latitude, longitude){
  const accessToken = creds.GOOGLE_API_KEY;
  let resp;
  try{
    const data = {
        latlng: latitude.toString()+','+longitude.toString(),
        key: accessToken,
    }
    resp = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {params: {...data}});
  // console.log(resp.data.results[0]);
  } catch (err){
    resp = err?.response
  } 

  if(resp?.status === 200){
    const rrr = await FormatAddress(resp?.data?.results?.[0], "Google");
    return rrr;
  }

  return {}
}


export async function GetCoordinatesByAddress_Google(address){
  const accessToken = creds.GOOGLE_API_KEY;
  const data = {
      address: address,
      key: accessToken,
  }
  const resp = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {params: {...data}});
  // console.log(resp.data.results[0]);
  //console.log(rrr);
  return resp.data.results;
}

export async function GetStateCityByPincode(pincode){
  try{
    const resp = await axios.get(`https://api.postalpincode.in/pincode/${pincode}`);
    const Data = resp.data[0]?.PostOffice[0];
    const obj = {"State": Data.State, "City": Data.District };
    return {"Status":"Success", "Resp":obj};
  }
  catch(err){
    return {"Status":"Error", "Resp":err.toString()};
  }

}

export async function GetAutocompletePlaceSuggestions_Google(input){
  const accessToken = creds.GOOGLE_API_KEY;
  const data = {
      input,
      key: accessToken,
  }
  try{
    const resp = await axios.get('https://maps.googleapis.com/maps/api/place/autocomplete/json', {params: {...data}});
    return resp;
  } catch (err){
    return err?.response;
  }
}

export async function GetPlaceDetails_Google(placeId){
  const accessToken = creds.GOOGLE_API_KEY;
  const data = {
      place_id: placeId,
      key: accessToken,
  }
  try{
    const resp = await axios.get('https://maps.googleapis.com/maps/api/place/details/json', {params: {...data}});
    return resp;
  } catch (err){
    return err?.response;
  }
}