import axios from "axios";
import { getToken, getUserId } from "../apiService";
import { Envs } from "../../components/GlobalComp/Env";
import { Endpoint_Paths } from "../EndpointPaths";

const backendURL = Envs?.[process.env.NODE_ENV]?.BACKEND_URL

// =================================================
// =============== LOAD RATES =====================
// =================================================

// ADD LOAD UNLOAD RATES
export async function addLoadUnloadRate(companyId, loadId, warehouseId, loadDetails) {
    const requesterId = getUserId()
    const token = await getToken();
    // console.log(token);
    return await axios.post(`${backendURL}${Endpoint_Paths.ADD_LOADRATE}`, 
        loadDetails,    
        {
            params:{
                companyId,
                entityId: warehouseId,
                loadId,
                requesterId
            },
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
  }

// GET LOAD RATES
export async function getLoadRate(warehouseId, companyId) {
    try{
        const token = await getToken();
        const resp = await axios.get(`${backendURL}${Endpoint_Paths.GET_LOADRATE}`, {
            params:{
                companyId,
                entityId: warehouseId
            },    
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        return resp;
    } catch (err){
        return err?.response
    }
  }

// EDIT LOAD RATES
export async function updateLoadRate(warehouseId, loadDetails, companyId) {
    try{
    const token = await getToken();
    const requesterId = getUserId()
    return await axios.put(`${backendURL}${Endpoint_Paths.EDIT_LOADRATE}`, 
        loadDetails,    
        {
            params:{
                companyId,
                entityId: warehouseId,
                requesterId
            },
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
    } catch (err) {
        return err?.response
    }
  }

  export async function addNewLoadRates(warehouseId, obj){
    const token = await getToken();
    const requesterId = getUserId()
    return await axios.post(`${backendURL}${Endpoint_Paths.ADD_LOADRATE}`, obj, {
        params:{
            entityId: warehouseId,
            requesterId
        },    
        headers:{
            'apikey': 'k3n5POSr7Rn2jMWksmY',
            'token': token
        }
    });
}