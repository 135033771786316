// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC0dBaNlZXUpwaJdI2EPb75eXCyMzsOIFc",
    authDomain: "infinityhaul-704f2.firebaseapp.com",
    databaseURL: "https://infinityhaul-704f2-default-rtdb.firebaseio.com",
    projectId: "infinityhaul-704f2",
    storageBucket: "infinityhaul-704f2.appspot.com",
    messagingSenderId: "271241988102",
    appId: "1:271241988102:web:77aa3479e6e275a566db87",
    measurementId: "G-WFPL3X6PDM"
  };

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Export the firebase app object
export default firebaseApp;