import axios from "axios";
import { getToken, getUserId } from "../apiService";
import { Envs } from "../../components/GlobalComp/Env";
import { Endpoint_Paths } from "../EndpointPaths";

const backendURL = Envs?.[process.env.NODE_ENV]?.BACKEND_URL

export async function getUserDetails(mobile_no, firebasetoken) {
    const token = await getToken();
    try{
        const loginResp = await axios.get(`${backendURL}${Endpoint_Paths.LOGIN}`, {
            params:{
                mobNo: mobile_no,
                deviceToken: firebasetoken,
                device: 'web'
            },    
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        return loginResp;

    }
    catch(err){
        return err?.response
    }
  }

  export async function checkIfExists(mobileNo){
      const resp = await getUserDetails(mobileNo);
      if(resp.status==="success"){
        if(Object.keys(resp.data.Data || {}).length>0){
            return true;
        }
      }
      return false;
  }

  export async function registerUser(signupData) {
    const token = await getToken();
    let signupResp;
    if(token){
        try{
            signupResp = await axios.post(`${backendURL}${Endpoint_Paths.SIGNUP}`, signupData,
                {    
                    headers:{
                        'apikey': 'k3n5POSr7Rn2jMWksmY',
                        'token': token
                    }
                });
            if(signupResp?.status === 200){
                return {status:'success', data: signupResp.data}
            }
        }
        catch(err){
            return {status:'error', data: err.response?.data?.msg || ''}
        }
    }
    else 
        return {status:'error', data: "token error"};
  }

  export async function updateUsers(userId, obj){
    const token = await getToken();
    const requesterId = getUserId()
    try{
        const resp = await axios.put(`${backendURL}${Endpoint_Paths.EDIT_USER}`, obj, {
        params:{
            userId,
            requesterId
        },    
        headers:{
            'apikey': 'k3n5POSr7Rn2jMWksmY',
            'token': token
        }
    });
    if(resp.status === 200)
            return { data: resp.data, status:'success'}
        else
            throw new Error(resp);
    }
    catch(err){
        if(err.response.status === 400){
            return { msg: err.response.data.msg, status:'error'}
        }
    }
}


export async function logoutPrevSession(mobNo){
    try{
        const resp = await axios.post(`${backendURL}${Endpoint_Paths.FORCE_SIGNOUT}`, {}, {
            params:{
                mobNo,
                device: 'web'
            },    
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
            }
        });
        return resp
    }
    catch(err){
        console.log(err);
        return err.response
    }
}