import axios from "axios";
import { getToken, getUserId } from "../apiService";
import { Envs } from "../../components/GlobalComp/Env";
import { Endpoint_Paths } from "../EndpointPaths";

const backendURL = Envs?.[process.env.NODE_ENV]?.BACKEND_URL

// ==========================================================
// ================= REGION APIs =============================
// ==========================================================

// ADD NEW  REGION
export async function addNewRegion(companyId, regionName, userData) {
    const requesterId = getUserId()
    const token = await getToken();
    try{
        
        const resp = await axios.post(`${backendURL}${Endpoint_Paths.ADD_REGION}`, 
            {
                regionName,
                RegionHeadDetails: {...userData}
            },    
            {
                params: {
                    companyId: companyId,
                    requesterId
                },
                headers:{
                    'apikey': 'k3n5POSr7Rn2jMWksmY',
                    'token': token
                }
            });
        return { data: resp.data, status:'success'}
    }
    catch(err){
        if(err.response.status === 400){
            return { msg: err.response.data.msg, status:'error' }
        }
        return { data: err?.response?.data ?? 'Some Error Occurred', status: 'error' }
    }
  }

  // Get ALL REGION BY COMPANY-ID
  export async function getAllRegions(companyId) {
    try{
        const token = await getToken();
        const resp = await axios.get(`${backendURL}${Endpoint_Paths.GET_ALL_REGIONS}`, {
            params: {
                companyId: companyId
            },
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        return resp
    } catch(err){
        return err?.response
    }
  }

  // Get ONE REGION by COMPANY-ID & REGION-ID
  export async function getOneRegion(companyId, regionId) {
      try {
        const token = await getToken();
        const resp =  await axios.get(`${backendURL}${Endpoint_Paths.GET_SINGLE_REGION}`, {
            params:{
                "companyId": companyId,
                regionId
            },    
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        return resp
    } catch(err){
        return err?.response
    }
  }

  // UPDATE REGION Details
  export async function updateRegion(companyId, regionId, updatedData) {
    const requesterId = getUserId()
    const token = await getToken();
    try{
        const resp = await axios.put(`${backendURL}${Endpoint_Paths.EDIT_REGION}`,updatedData,
        {
            params:{
                "companyId": companyId,
                "regionId": regionId,
                // "headId": headId,
                requesterId
            },    
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        if(resp.status === 200)
            return { data: resp?.data, status:'success'}
        else
            throw new Error(resp.response);
    }
    catch(err){
        return { data: err?.response?.data, status: 'error' }
    }
  }

  // DELETE REGION 
  export async function deleteRegion(companyId, regionId, headId) {
    try {
        const token = await getToken();
        const requesterId = getUserId()
        const resp = await axios.delete(`${backendURL}${Endpoint_Paths.DELETE_REGION}`, {  
            params: {
                companyId: companyId,
                regionId, 
                headId,
                requesterId
            },
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        return resp
    } catch (err){
        return err?.response
    }
  }

  // Region Data Excel File Upload  
  export async function uploadRegionExcel(companyId, excelFile) {
    const token = await getToken();
    const requesterId = getUserId()
    try{
        const resp = await axios.post(`${backendURL}${Endpoint_Paths.UPLOAD_REGION_EXCEL}`, excelFile, {
            params:{
                "companyId": companyId,
                requesterId
            },    
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        if(resp.status===200)
            return { status: 'success', data: {...resp.data} }
        else
            throw new Error(resp);
    }
    catch(err){
        if(err.response.status === 400){
            return {status:'error', data: Object.keys(err.response.data).join(',')}
        }
        else{
            return {status:'error', data: 'Internal Server Error'}
        }
    }
  }

// Region Data Excel File Upload  
export async function uploadArchitectureExcel(companyId, excelFile) {
    const token = await getToken();
    const requesterId = getUserId()
    try{
        const resp = await axios.post(`${backendURL}/regionExcelUpload`, excelFile, {
            params:{
                "companyId": companyId,
                requesterId
            },    
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        if(resp.status===200)
            return { status: 'success', data: {...resp.data} }
        else
            throw new Error(resp.data);
    }
    catch(err){
        if(err?.response?.status === 400){
            return {status:'error', data: err.response.data}
        }
        else{
            return {status:'error', data: 'Error while scanning uploaded file'}
        }
    }
  }
