import React, { useEffect, useMemo, useState } from 'react'
import { GetCountryDetailByCode, GetListOfCountries } from '../../API/ModuleAPIs/AdditionalAPI';
import { isEmpty } from 'lodash';
import { Autocomplete, TextField } from '@mui/material';
import { useSelector } from 'react-redux';

const CountrySelectionContext = React.createContext();

/**
 * A React component that wraps its children in a context provider for managing the country selection state.
 * It fetches the list of countries from an API and provides functions to set the selected country.
 *
 * @param {Object} props - The component's props.
 * @param {React.ReactNode} props.children - The children components to be wrapped in the context provider.
 * @returns {React.ReactElement} - The wrapped children components with the country selection context.
 */
export default function CountrySelectionContextWrapper ({ children }) {
  // States
  const [countryList, setCountryList] = useState([])
  const [selectedCountry, setSelectedCountry] = useState({ label: "", value: ""})
  const [selectedCountryData, setSelectedCountryData] = useState({})

  // Fetching Country from localStorage
  const savedCountry = useMemo(() => localStorage.getItem('selectedCountry'), [localStorage])

  // Redux State
  const reduxState = useSelector(state => state)

  useEffect(() => {
    if(!isEmpty(savedCountry) && !isEmpty(selectedCountry)) {
      setCountry(savedCountry);
    }
  }, [savedCountry, countryList])

  // handler to fetch country list
  const fetchCountryList = async () => {
    try {
      const response = await GetListOfCountries()
      if(response.status === 200) {
        setCountryList(response?.data?.data);
      }
    } catch (error) {
      console.error('Error fetching country list:', error);
    }
  }

  // Function to fetch Country data by selectedCountry code
  const fetchCountryData = async (callingCode) => {
    // Fetching country information
    const resp2 = await GetCountryDetailByCode(callingCode)
    if(resp2.status === 200) {
      const countryData = { ...resp2.data?.[0] }
      const callingCode = `${countryData?.idd?.root}${countryData?.idd?.suffixes?.[0]}`
      setSelectedCountryData({...countryData, callingCode })
    }
  }

  const renderCountrySelection = (updateSelectCountry) => {
    return (
      <Autocomplete
        options={countryOptions}
        getOptionLabel={(option) => option.label}
        value={selectedCountry ?? ''}
        onChange={(event, newValue) => {
          setSelectedCountry(newValue);
          updateSelectCountry?.(newValue)
        }}
        renderInput={(params) => <TextField {...params} />}
        isOptionEqualToValue={(option, value) => option.value === value?.value}
      />
    )
  }

/**
 * Sets the selected country based on the provided country code.
 * If the country code is not empty, it iterates through the country list to find the matching country.
 * If a match is found, it updates the selectedCountry state and saves the country code in localStorage.
 * 
 * @param {string} countryCode - The code of the country to be selected.
 * @returns {void}
 */
const setCountry = (countryCode) => {
  if(!isEmpty(countryCode)) {
    Object.entries(countryList).forEach(([code, country]) => {
      if(code === countryCode) {
        setSelectedCountry({...country, countryCode: code });
        isEmpty(savedCountry) && localStorage.setItem('selectedCountry', code);
      }
    })
  }
}

  useEffect(() => {
    fetchCountryList()
  }, [])

  useEffect(() => {
    if(!isEmpty(selectedCountry)){
      fetchCountryData(selectedCountry.value)
    }
  }, [selectedCountry])

  useEffect(() => {
    if (reduxState.isLoaded){
      const { country } = reduxState?.userData
      if (!isEmpty(country)) {
        // Fetching Country calling code from country name
        const foundObj = Object.entries(countryList)?.find(([countryCode, countryObj]) => {
          if (countryObj?.country?.toLowerCase() === country?.toLowerCase()) {
            return({ label: countryObj?.country, value: countryCode})
          }
        })
        selectedCountry(foundObj ?? { label: "India", value: "IN"})
        setCountry(country?.countryCode)
      }
    }
  }, [reduxState])
  
  

  // Meomized value
  // Convert countryData into an array of options
  // This will contain data like { label: "India", value: "IN", ...}
  const countryOptions = useMemo(() => Object.keys(countryList).map((key) => ({
                          label: countryList?.[key]?.country,
                          value: key
                        })), [countryList])

  const state = {
    countryList,
    selectedCountry: { ...selectedCountry, info: selectedCountryData},
    countryOptions,
    setCountry,
    renderCountrySelection
  }

  return (
    <CountrySelectionContext.Provider value={{...state}}>
        { children }
    </CountrySelectionContext.Provider>
  )
}

export { CountrySelectionContextWrapper, CountrySelectionContext };
