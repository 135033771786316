import axios from "axios";
import { getToken, getUserId } from "../apiService";
import { Envs } from "../../components/GlobalComp/Env";
import { Endpoint_Paths } from "../EndpointPaths";

const backendURL = Envs?.[process.env.NODE_ENV]?.BACKEND_URL

  // ======================================
  // ========= LOAD FAMILIES API ==========
  // ======================================

export async function getLoadFamily(companyId, truckId) {
  try {
    const token = await getToken();
    // console.log(token);
    const resp = await axios.get(`${backendURL}${Endpoint_Paths.GET_LOAD_FAMILIES}`, {
      params: {
        "companyId": companyId,
        vehicleTypeId: truckId
      },
      headers: {
        'apikey': 'k3n5POSr7Rn2jMWksmY',
        'token': token
      }
    });
    return resp
  } catch (err) {
    return err?.response
  }
}

export async function getAllLoadFamily(companyId) {
  try {
    const token = await getToken();
    // console.log(token);
    const resp = await axios.get(`${backendURL}${Endpoint_Paths.GET_ALL_LOAD_FAMILIES}`, {
      params: {
        "companyId": companyId
      },
      headers: {
        'apikey': 'k3n5POSr7Rn2jMWksmY',
        'token': token
      }
    });
    return resp
  } catch (err) {
    return err?.response
  }
}

  export async function deleteLoadFromFamily(companyId, truckId, loads) {
    try {
      const requesterId = getUserId()
      const token = await getToken();
      const obj = {"loads": [loads]};
      const resp = await axios({
          method: 'delete',
          url: `${backendURL}${Endpoint_Paths.DELETE_LOAD_FAMILIES}?companyId=${companyId}&vehicleTypeId=${truckId}&requesterId=${requesterId}`,
          data: {
            ...obj
          },
          headers:{
              'apikey': 'k3n5POSr7Rn2jMWksmY',
              'token': token
          }
        });
        return resp;
    } catch (err) {
      return err?.response
    }
  }


export async function addLoadFamily(companyId, truckId, loads) {
  const requesterId = getUserId()
  try {
    const token = await getToken();
    //console.log('Loads => ', loads);
    const resp = await axios.post(`${backendURL}${Endpoint_Paths.ADD_LOAD_FAMILIES}`, { "loads": loads }, {
      params: {
        "companyId": companyId,
        vehicleTypeId: truckId,
        requesterId
      },
      headers: {
        'apikey': 'k3n5POSr7Rn2jMWksmY',
        'token': token
      }
    });
    return resp
  } catch (err) {
    return err?.response
  }

}