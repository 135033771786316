import axios from "axios";
import { getToken, getUserId } from "../apiService";
import { Envs } from "../../components/GlobalComp/Env";
import { Endpoint_Paths } from "../EndpointPaths";

const backendURL = Envs?.[process.env.NODE_ENV]?.BACKEND_URL

// =================================================================
// ====================== Deligations APIs ========================
// =================================================================
export async function addNewDelignation(companyId, obj){
    const requesterId = getUserId()
    const token = await getToken();
    try{
        const resp = await axios.post(`${backendURL}${Endpoint_Paths.ADD_DELIGATION}`,obj, {
            params:{
                "companyId": companyId,
                requesterId
            }, 
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        if(resp.status === 200)
            return { data: resp?.data, status:'success'}
        else
            throw new Error(resp);
    }
    catch(err){
        if(err?.response?.status === 400){
            return { msg: err?.response?.data?.msg, status:'error'}
        }
        return { msg: err?.response?.message ?? err?.message ?? 'Some Error Occurred', status:'error'}
    }
}