import axios from "axios";
import { getToken, getUserId } from "../apiService";
import { Envs } from "../../components/GlobalComp/Env";
import { Endpoint_Paths } from "../EndpointPaths";

const backendURL = Envs?.[process.env.NODE_ENV]?.BACKEND_URL

// ==========================================================
// ================= TRUCK APIs =============================
// ==========================================================


// ADD NEW  TRUCK
export async function addNewTruck(companyId, truckDetails) {
    const token = await getToken();
    const requesterId = getUserId()
    try{
        const resp = await axios.post(`${backendURL}${Endpoint_Paths.ADD_TRUCK}`, 
            truckDetails,    
            {
                params: {
                    companyId: companyId,
                    requesterId
                },
                headers: {
                    'apikey': 'k3n5POSr7Rn2jMWksmY',
                    'token': token
                }
            });
        if(resp.status === 200)
            return { data: resp.data, status:'success'}
        else
            throw new Error(resp);
    }
    catch(err){
        if(err.response.status === 400){
            return { msg: err.response.data.msg, status:'error' }
        }
    }
  }

  // Get ALL TRUCK BY COMPANY-ID
  export async function getAllTrucks(companyId) {
    const token = await getToken();
    // console.log(token);
    return await axios.get(`${backendURL}${Endpoint_Paths.GET_ALL_TRUCKs}`, {
        params:{
            "companyId": companyId
        },    
        headers:{
            'apikey': 'k3n5POSr7Rn2jMWksmY',
            'token': token
        }
    });
  }

  // Get ONE TRUCK by COMPANY-ID & TRUCK-ID
  export async function getOneTruck(companyId, truckId) {
    try{
        const token = await getToken();
        const requesterId = getUserId()
        return await axios.get(`${backendURL}${Endpoint_Paths.GET_SINGLE_TRUCK}`, {
            params:{
                "companyId": companyId,
                vehicleTypeId: truckId,
                requesterId
            },    
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
    }
    catch(err){
        return err.response
    }
  }

  // UPDATE TRUCK Details
  export async function updateTruck(companyId, truckId, updatedTruckDetails) {
    const token = await getToken();
    const requesterId = getUserId()
    // console.log(token);
    try{
        const resp = await axios.put(`${backendURL}${Endpoint_Paths.EDIT_TRUCK}`, updatedTruckDetails,
        {
            params:{
                companyId,
                vehicleTypeId: truckId,
                requesterId: requesterId
            },    
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        if(resp.status === 200)
            return { data: resp.data, status:'success'}
        else
            throw new Error(resp);
        }
    catch(err){
        if(err.response.status === 400){
            return { msg: err.response.data.msg, status:'error' }
        }
        return { msg: err?.response?.data?.msg ?? err?.message ?? 'Some Error Occurred', status: 'error'}
    }
  }

  // DELETE TRUCK 
  export async function deleteTruck(companyId, truckIds) {
    const token = await getToken();
    const requesterId = getUserId()
    const payload = {
        vehicleTypeIds: [...truckIds]
    }
    return await axios.delete(`${backendURL}${Endpoint_Paths.DELETE_TRUCK}`, {
        params:{
            "companyId": companyId,
            requesterId
        },    
        headers:{
            'apikey': 'k3n5POSr7Rn2jMWksmY',
            'token': token
        },
        data: payload
    });
  }

  // UPLOAD EXCEL FILE for TRUCKS
  export async function uploadTrucksExcel(companyId, excelFile) {
    const token = await getToken();
    const requesterId = getUserId()
    try{
        const resp = await axios.post(`${backendURL}/truckExcelUpload`, excelFile, {
            params:{
                "companyId": companyId,
                requesterId
            },    
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        if(resp.status===200)
            return { status: 'success', data: {...resp.data} }
        else
            throw new Error(resp);
    }
    catch(err){
        if(err.response.status === 400){
            return {status:'error', data: err.response.data}
        }
        else{
            return {status:'error', data: 'Internal Server Error'}
        }
    }
  }

  export async function getVehicleData(companyId, vehicleNo){
    const token = await getToken();
    return await axios.get(`${backendURL}/getVehicleDetails`, {
        params:{
            "companyId": companyId,
            vehicleNo
        },    
        headers:{
            'apikey': 'k3n5POSr7Rn2jMWksmY',
            'token': token
        }
    });
  }