import axios from "axios";
import { getToken, getUserId } from "../apiService";
import { Envs } from "../../components/GlobalComp/Env";
import { Endpoint_Paths } from "../EndpointPaths";

const backendURL = Envs?.[process.env.NODE_ENV]?.BACKEND_URL

// =================================================================
// ====================== Notifications APIs =======================
// =================================================================
export async function getAllNotifications(companyId, userId){
    const token = await getToken();
    try{
        const resp = await axios.get(`${backendURL}${Endpoint_Paths.GET_NOTIFICATIONS}`, {
            params:{
                "companyId": companyId,
                "userId" : userId
            }, 
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        if(resp?.status === 200)
            return { data: {...(resp?.data?.Data ?? {})}, status:'success'};
        else
            throw new Error(resp);
    }
    catch(err){
        if(err?.response?.status === 400){
            return { msg: err.response.data.msg, status:'error'}
        }
        return { msg: 'Some Error Occurred', status:'error'}
    }
}


export async function markNotificationAsRead(companyId, notifId){
    const token = await getToken();
    const requesterId = getUserId()
    try{
        const resp = await axios.put(`${backendURL}${Endpoint_Paths.MARK_NOTIFICATION_READ}?companyId=${companyId}&notificationId=${notifId}`, {}, {
            params: {
                requesterId
            },
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token,
                'Content-Length':0
            }
        });
        if(resp.status === 200)
            return { msg: resp.data.msg, status:'success'}
        else
            throw new Error(resp);
    }
    catch(err){
        if(err?.response?.status === 400){
            return { msg: err.response.data.msg, status:'error'}
        }
    }
}