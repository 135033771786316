import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import React, { createContext, memo, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import firebaseApp from '../Notifications/firebase';
import { login, logout, setReduxDataLoaded, setReduxError } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../API/apiService';
import { DesignationID } from '../GlobalComp/constant/DesignationId';
import { Envs } from '../GlobalComp/Env';
import Cookies from 'universal-cookie';
import { isEmpty } from 'lodash';
import { logoutPrevSession } from '../../API/ModuleAPIs/UserAPI';
import Swal from 'sweetalert2';
import MainLoadingComponent from '../GlobalComp/MainLoadingComp';


// Create the context
export const MainContext = createContext();

const MainContextWrapper = ({ children }) => {
  // @ States
    const [messages, setMessages] = useState([]);
    const [newNotifCount, setnewNotifCount] = useState(0)
    const [firebaseToken, setfirebaseToken] = useState('')
    const [activeTutorial, setactiveTutorial] = useState(false)
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    // @ Redux State
    const reduxState = useSelector(state => state)
  
    useEffect(() => {
      try {
        // get the messaging object from firebase
        const messaging = getMessaging(firebaseApp);

        // request permission to receive notifications
        getToken(messaging, { vapidKey: "BEfywJ5AyF9O4VCciyt_-Q6eIum-ZhhVCg8EHpVfwDVS8MW7b9vlzrZv1vnppcvx-a0FBO_FY2E889KPgwN_9x8" })
          .then((currentToken) => {
            if (currentToken) {
              setfirebaseToken(currentToken);
              console.log(currentToken);
              // send currentToken to your server if you want to
            } else {
              console.log("No registration token available. Request permission to generate one.");
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
          });

        // listen for messages when the app is in the foreground
        onMessage(messaging, (payload) => {
          // console.log("Message received. ", payload);
          // update the state with the new message
          setMessages((prevMessages) => [...prevMessages, payload]);
          setnewNotifCount(prev => prev + 1)
        });
      } catch (err) {
        console.log('Firebase Messaging Error')
      }
    }, []);
  
    useEffect(() => {
      if(newNotifCount > 0){
        toast.info('Notification Recieved');
      }
    }, [newNotifCount])


    // ============ User management ========
    const [loggedUser, setLoggedUser] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [accessId, setAccessId] = useState(1);

    // Cookies
    const [cookies] = useState(new Cookies());

    // Redux Updater
    const dispatch = useDispatch();

    
      const handleUser = (dat) => {
        localStorage.setItem('mobileNumber', dat?.number);
        localStorage.setItem('userId', dat?.userId);
        setLoggedUser(dat);
      };
    
      const clearStates = () => {
        dispatch(logout());
        dispatch(setReduxError(false))
        localStorage.removeItem('mobileNumber');
        localStorage.removeItem('userId');
        setLoggedUser({});
        setAccessId('');
      };
    
      const UpdateAccessId = (designationId = '') => {
        let did = loggedUser?.designationId;
        if (designationId !== '') {
          did = designationId;
        }
        if (did?.toString() === "0") {
          setAccessId(Number(loggedUser?.deligationId));
        } else {
          setAccessId(Number(loggedUser?.designationId));
        }
      };

      const updateOnlineStatus = () => {
        setIsOnline(navigator.onLine);
      };

      const setLoggedinUserData = (Data) => {
        dispatch(login(Data)); // Upating Redux

        setLoggedUser({
          ...(Data ?? {})
        });
        setAccessId(Number(Data?.designationId));

        if (Data.designationId === DesignationID.SUPERVISOR && cookies.get('supervisorlogin')) {
          window.location.href = Envs?.[process.env.NODE_ENV]?.REACT_APP_SUPERVISOR_URL;
        }
      }
    
      const fetchUserDetails = async () => {
        if (!isOnline) { return; }
        setLoaded(false)
        const mobileNum = localStorage.getItem('mobileNumber');
        // const userId = localStorage.getItem('userId');

        if (!isEmpty(mobileNum)) {
          console.log('Mobile Number ==>', mobileNum);
          // Forcing Logout
          const logoutResp = await logoutPrevSession(mobileNum);
          if (logoutResp?.status === 200) {
            // Prev Session Killed, Continueing to re-login
            const resp = await getUserDetails(mobileNum, firebaseToken);
            if (resp.status === 200) {
              setLoggedinUserData(resp?.data?.Data)
              UpdateAccessId(resp?.data?.Data?.designationId);
              handleUser(resp?.data?.Data)
            } else {
              Swal.fire(resp?.data?.msg ?? resp?.message, 'Error occurred', 'error')
            }
          } // END-logoutResp-if

        }
        // Updating Redux
        dispatch(setReduxDataLoaded(true))

      }; // end-of-fetchUserDetails


      // Checking if Internet is connected
      useEffect(() => {
        // Set up event listeners for online and offline events
        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);
    
        // Cleanup the event listeners when the component unmounts
        return () => {
          window.removeEventListener('online', updateOnlineStatus);
          window.removeEventListener('offline', updateOnlineStatus);
        };
      }, []);

    
      useEffect(() => {
        console.log('FireBase Token =>', firebaseToken);
        try{
            console.log('Fetching User Data...');
            fetchUserDetails();
        }
        catch(err){
              console.log('User Error ==>', err);
              dispatch(setReduxError(true))
        }
        finally{
          setLoaded(true);
        }
      }, []);
    

  
  const contextValue = {
    notificationMessages: messages,
    firebaseToken,
    handleUser,
    clearStates,
    UpdateAccessId,
    loaded,
    accessId,
    activeTutorial, 
    setactiveTutorial
  };

  // Provide the context value to the children components
  return (
    <MainContext.Provider value={contextValue}>
      {
        loaded ? children : <MainLoadingComponent/>
      }
    </MainContext.Provider>
  );
};

export default memo(MainContextWrapper)