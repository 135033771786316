export const Envs={
    development:{
        REACT_APP_SUPERVISOR_URL:'http://localhost:3001',
        REACT_APP_ONBOARDING_URL:'http://localhost:3000',
        DOMAIN:'localhost',
        // BACKEND_URL: 'http://onboardinggcp.infinityhaul.com'
        BACKEND_URL: 'http://34.139.123.254' //'http://34.75.53.232'
    },
    production:{
        REACT_APP_SUPERVISOR_URL:'https://supervisor.infinityhaul.com',
        REACT_APP_ONBOARDING_URL:'https://onboarding.infinityhaul.com',
        DOMAIN:'.infinityhaul.com',
        BACKEND_URL: 'http://34.139.123.254'
    }
}

export const TWO_FACTOR_CONFIG = {
    API_KEY:'b6191fc5-3996-11ed-9c12-0200cd936042'
}