import { createSlice, configureStore } from '@reduxjs/toolkit'
const counterSlice = createSlice({  
    name: 'user',  
    initialState: {    
        userData: {},
        isLoggedin: false,
        isLoaded: false,
        isError: false  
    },  
    reducers: {   
        login: (state, action) => {      
            state.userData = {...action.payload}
            state.isLoggedin = true
        },    
        logout: state => {      
            state.userData = {}
            state.isLoggedin = false    
        },
        setReduxDataLoaded: (state, action) => {
            state.isLoaded = action?.payload ??  false
        },
        setReduxError: (state, action) => {
            state.isError = action?.payload ?? false
        }  
    }})
export const { login, logout, setReduxDataLoaded, setReduxError } = counterSlice.actions
const store = configureStore({  reducer: counterSlice.reducer},  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;