import React from "react";
import 'react-toastify/dist/ReactToastify.css';
import MainContextWrapper from "./components/ContextAPI/MainContext";
import MainComp from "./MainComp";
import CountrySelectionContextWrapper from "./components/ContextAPI/CountrySelectionContext";

  const App = () => {  
    return (
      <>
        <CountrySelectionContextWrapper>
          <MainContextWrapper>
            <MainComp/>
          </MainContextWrapper>
        </CountrySelectionContextWrapper>
      </>
    );
  };
  

// Mapping Redux State To Props
// const mapStateToProps = state => ({
//     userData: state.userData,
//     loggedIN: state.isLoggedin,
// });

export default App;
