import axios from "axios";
import { getToken, getUserId } from "../apiService";
import { Envs } from "../../components/GlobalComp/Env";
import { Endpoint_Paths } from "../EndpointPaths";
import { isEmpty } from "lodash";

const backendURL = Envs?.[process.env.NODE_ENV]?.BACKEND_URL

// ===============================================
// ============== WAREHOUSE API =================
// ===============================================

export async function getWarehouseId(companyId, entity,  TOKEN=''){
    let token = TOKEN;
    if(TOKEN === '')
        token = await getToken();
    // console.log(token);
    const resp = await axios.get(`${backendURL}${Endpoint_Paths.ADD_WAREHOUSE_ID}`, {
        params: {
            companyId: companyId, 
            entity:entity
        },
        headers:{
            'apikey': 'k3n5POSr7Rn2jMWksmY',
            'token': token
        }
    });

    return resp.data.Data.warehouseId || '';
}

export async function addNewWarehouse(companyId, regionId, entity=0, body){
    try{
        const requesterId = getUserId()
        const token = await getToken();
        // const wid = await getWarehouseId(companyId, entity, token);
        const resp = await axios.post(`${backendURL}${Endpoint_Paths.ADD_NEW_WAREHOUSE}`, body, {
            params:{
                "companyId": companyId,
                regionId,
                entity,
                requesterId
            },    
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        return resp;
    }
    catch(err){
        return err?.response
    }
}


export async function addNewUserToWarehouse(companyId, warehouseId, body){
    const token = await getToken();
    const requesterId = getUserId()
    try{
        if(isEmpty(warehouseId)){
            console.log('WarehouseId missing');
            return;
        }
        let AddUserURL = ''
        if(body?.designation?.includes('Manager')){
            AddUserURL = Endpoint_Paths.ADD_WAREHOUSE_MANAGER
        } else if (body?.designation?.includes('Supervisor')){
            AddUserURL = Endpoint_Paths.ADD_WAREHOUSE_SUPERVISOR
        } else if (body?.designation?.includes('Loading Coordinator')){
            AddUserURL = Endpoint_Paths.ADD_WAREHOUSE_LOADING_COORDINATOR
        }
        const resp = await axios.post(`${backendURL}${AddUserURL}`, body, {
            params:{
                "companyId": companyId,
                "entityId": warehouseId,
                // "regionId": regionId,
                requesterId
            },    
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        return resp;
    }
    catch(err){
        return err?.response
    }
}

export async function getWarehousesByRegion(companyId, regionId) {
    const token = await getToken();
    // console.log(token);
    try{
    const resp = await axios.get(`${backendURL}${Endpoint_Paths.GET_ALL_WAREHOUSE}`, {
        params:{
            "companyId" : companyId,
            "regionId" : regionId,
        },
        headers:{
            'apikey': 'k3n5POSr7Rn2jMWksmY',
            'token': token
        }
    });

    return resp.data;
    }
    catch(err){
        if(err.response.status === 400){
            return { data: err.response.data.msg, status:'error'}
        }
    }

}

export async function getOneWarehouse(companyId, warehouseId) {
    try{
    const token = await getToken();
    // console.log(token);
    const resp = await axios.get(`${backendURL}${Endpoint_Paths.GET_SINGLE_WAREHOUSE}`, {
            params:{
                "companyId" : companyId,
                "entityId" : warehouseId,
            },
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
    
        return resp;
    } catch (err) {
        return err?.response
    }

}


export async function updateWarehouse(companyId, wid, body){
    const requesterId = getUserId()
    try{
        const token = await getToken();
        const resp = await axios.put(`${backendURL}${Endpoint_Paths.EDIT_WAREHOUSE}`, body, {
            params:{
                "companyId": companyId,
                "entityId": wid,
                requesterId
            },    
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        return resp;
    } catch (err){
        return err?.response
    }
}

export async function deleteWarehouse(companyId, wids){
    try{
        const requesterId = getUserId()
        const token = await getToken();
        const payload = {
            "warehouses": [...wids]
        }
        const resp = await axios.delete(`${backendURL}${Endpoint_Paths.DELETE_WAREHOUSE}`, {
            params:{
                "companyId": companyId,
                requesterId
            },    
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            },
            data: payload
        });
        return resp;
    } catch (err){
        return err?.response
    }
}

// ===== WAREHOUSE USERS API =========
export async function getWarehouseUsers(companyId, warehouseId){
    const token = await getToken();
    const resp = await axios.get(`${backendURL}${Endpoint_Paths.GET_WAREHOUSE_USERS}`, {
        params:{
            "companyId": companyId,
            "entityId": warehouseId,
        },    
        headers:{
            'apikey': 'k3n5POSr7Rn2jMWksmY',
            'token': token
        }
    });
    return resp.data;
}

export async function updateWarehouseUsers(userId, companyId, entityId, obj, designation){
    const token = await getToken();
    const requesterId = getUserId()
    try{
        // Endpoint Selection Based on designation
        let EditUserURL = ''
        if(designation?.includes('Manager')){
            EditUserURL = Endpoint_Paths.EDIT_WAREHOUSEUSER_MANAGER
        } else if (designation?.includes('Supervisor')){
            EditUserURL = Endpoint_Paths.EDIT_WAREHOUSEUSER_SUPERVISOR
        } else if (designation?.includes('Loading Coordinator')){
            EditUserURL = Endpoint_Paths.EDIT_WAREHOUSEUSER_LOADING_COORDINATOR
        }
        const resp = await axios.put(`${backendURL}${EditUserURL}`, obj, {
        params:{
            "userId": userId,
            companyId,
            requesterId,
            entityId
        },    
        headers:{
            'apikey': 'k3n5POSr7Rn2jMWksmY',
            'token': token
        }
    });
    if(resp.status === 200)
            return { data: resp.data, status:'success'}
        else
            throw new Error(resp);
    }
    catch(err){
        if(err.response.status === 400){
            return { msg: err.response.data.msg, status:'error'}
        }

        return { msg: err?.response?.data?.msg, status:'error'}

    }
}

export async function deleteWarehouseUser(companyId, wid, userId){
    const token = await getToken();
    const requesterId = getUserId()
    const resp = await axios.delete(`${backendURL}${Endpoint_Paths.DELETE_WAREHOUSE_USER}`, {
        params:{
            "companyId": companyId,
            "warehouseId": wid,
            "userId":userId,
            requesterId
        }, 
        headers:{
            'apikey': 'k3n5POSr7Rn2jMWksmY',
            'token': token
        }
    });
    return resp.data;
}

export async function getCompanyWarehouses(companyId) {
    try{
        const token = await getToken();
        const resp = await axios.get(`${backendURL}${Endpoint_Paths.GET_ALL_WAREHOUSES_BY_COMPANY}`, {
            params:{
                companyId,
            },    
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });
        return resp;
    } catch (err) {
        return err?.response
    }
}