import axios from "axios";
import { getToken, getUserId } from "../apiService";
import { Envs } from "../../components/GlobalComp/Env";
import { Endpoint_Paths } from "../EndpointPaths";

const backendURL = Envs?.[process.env.NODE_ENV]?.BACKEND_URL

const requesterId = getUserId()



export async function getUniqueDates(companyId) {
    const token = await getToken();
    return await axios.get(`${backendURL}${Endpoint_Paths.GET_UNIQUE_DATES}`, {
        params:{
            "companyId": companyId,
            requesterId
        },    
        headers:{
            'apikey': 'k3n5POSr7Rn2jMWksmY',
            'token': token
        }
    });
  }

  export async function getSourceCities(companyId, year, month, load_family, date) {
    const token = await getToken();
    return await axios.get(`${backendURL}${Endpoint_Paths.GET_SOURCE_CITIES}`, {
        params:{
            "companyId": companyId,
            "year": year,
            "month": month, 
            "loadFamily": load_family, 
            "date": date,
        },    
        headers:{
            'apikey': 'k3n5POSr7Rn2jMWksmY',
            'token': token
        }
    });
  }

  export async function getFilteredStreams(companyId, year, month, load_family, date, source_city) {
    const token = await getToken();
    return await axios.get(`${backendURL}${Endpoint_Paths.GET_FILTERED_STREAMS}`, {
        params:{
            "companyId": companyId,
            "year": year,
            "month": month, 
            "loadFamily": load_family, 
            "date": date,
            "sourceCity": source_city
        },    
        headers:{
            'apikey': 'k3n5POSr7Rn2jMWksmY',
            'token': token
        }
    });
  }

  export async function getParticularStream(companyId, stream_id) {
    const token = await getToken();
    // console.log(token);
    return await axios.get(`${backendURL}${Endpoint_Paths.GET_PARTICULAR_STREAM}`, {
        params:{
            "companyId": companyId,
            "streamId": stream_id
        },    
        headers:{
            'apikey': 'k3n5POSr7Rn2jMWksmY',
            'token': token
        }
    });
  }

export async function getNSALoops(companyId){
    try{
        const token = await getToken();
        const resp = await axios.get(`${backendURL}${Endpoint_Paths.GET_NSA_DATA}`, {
            params:{
                "companyId": companyId,
            },   
            headers:{
                'apikey': 'k3n5POSr7Rn2jMWksmY',
                'token': token
            }
        });

        return resp;
    } catch (err){
        return err?.response
    }
}